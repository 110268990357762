.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.react-datepicker {
  display: flex !important
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  /* height: 30px; */
  height: auto !important;
}